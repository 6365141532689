<template>
    <styled-interface>
        <template #interface-toolbar>
            <month-picker v-model="currentDateRange" />
        </template>
        <template #interface-heading>
            Billing
        </template>
        <styled-card>
            <template #heading>
                Billing by Product
            </template>
            <template #action-buttons>
                <action-button
                    icon="download"
                    @click="exportCSV">
                    Export CSV
                </action-button>
            </template>
            <div class="groups-picker">
                <div class="groups-picker__holder">
                    <groups-picker-global :disabled="loading" />
                </div>
            </div>
            <div
                v-if="loading || groupsLoading"
                class="text-xs-center pa-5">
                <loader label="Loading Billing Data" />
            </div>
            <template v-else>
                <div class="layout px-4 py-4 justify-space-around row align-end mb-4 wrap">
                    <div
                        :class="{
                            'stats-element': true,
                            'active': (activeFilter === null)
                        }">
                        <div>
                            <span class="text-uppercase">
                                TOTAL PRODUCTS
                            </span>
                            <h4 class="font-weight-medium-bold light-blue--text">
                                <a @click.prevent="filterByProduct(null)">
                                    {{ dealerProducts.length }}
                                </a>
                            </h4>
                        </div>
                    </div>

                    <div
                        v-for="(product, index) in products"
                        :key="index"
                        :class="{
                            'stats-element': true,
                            'active': (activeFilter === product.product_id)
                        }">
                        <div>
                            <span class="text-uppercase">
                                {{ product.product }}
                            </span>
                            <h4 class="font-weight-medium-bold text-uppercase">
                                <a
                                    class="text--green"
                                    @click.prevent="filterByProduct(product.product_id)">
                                    {{ product.count }}
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>
                <custom-table
                    name="billing"
                    min-width="1200px"
                    :items="dealerProductsDisplayed"
                    :fields="fields"
                    :loading="loading"
                    :notification="notification"
                    :notification-type="notificationType"
                    :rows-per-page="50"
                    hide-sort
                    slim-cells />
            </template>
        </styled-card>
    </styled-interface>
</template>

<script>
import moment from 'moment';
import ActionButton from '@/components/globals/ActionButton';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import MonthPicker from '@/components/globals/MonthPicker';
import Loader from '@/components/globals/Loader';
import exportToCsv from '@/helpers/exportToCsv';
import loGet from 'lodash/get';
import loOmit from 'lodash/omit';
import HTTP from '@/http';
import { mapState } from 'vuex';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';

// Restructure for readability
const _ = { get: loGet, omit: loOmit };

export default {
    components: {
        ActionButton,
        StyledCard,
        CustomTable,
        StyledInterface,
        GroupsPickerGlobal,
        MonthPicker,
        Loader
    },
    title: 'Event Data Sources',
    data() {
        return {
            currentDateRange: {
                start: moment().startOf('month').format('YYYY-MM-DD'),
                end: moment().endOf('month').format('YYYY-MM-DD'),
            },
            pagination: {
                rowsPerPage: 25,
            },
            activeFilter: null,
            products: [],
            dealerProducts: [],
            dealerProductsDisplayed: [],
            notification: '',
            notificationType: '',
            fields: [
                {
                    id: 'name',
                    header: 'Dealer',
                    value: 'name',
                    align: 'left',
                    width: '20%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/resellers/BillingLink.vue'),
                    props: {
                        type: 'name'
                    }
                },
                {
                    id: 'id',
                    header: 'ID',
                    value: 'id',
                    align: 'left',
                    width: '5%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/resellers/BillingLink.vue'),
                    props: {
                        type: 'id'
                    }
                },
                {
                    id: 'site_base_url',
                    header: 'Website',
                    value: 'site_base_url',
                    align: 'left',
                    width: '23%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/resellers/BillingLink.vue'),
                    props: {
                        type: 'link'
                    }
                },
                {
                    id: 'external_id',
                    header: 'External ID',
                    value: 'external_id',
                    align: 'left',
                    width: '15%',
                    type: 'component',
                    component: () => import('@/components/resellers/BillingLink.vue'),
                    props: {
                        type: 'copy'
                    }
                },
                {
                    id: 'product',
                    header: 'Product',
                    value: 'product',
                    align: 'left',
                    width: '12%',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'product_started',
                    header: 'Started',
                    value: 'product_started.date',
                    align: 'left',
                    width: '15%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/resellers/BillingDate.vue'),
                    props: {
                        type: 'started'
                    }
                },
                {
                    id: 'product_ended',
                    header: 'Ended',
                    value: 'product_ended.date',
                    align: 'left',
                    width: '10%',
                    type: 'component',
                    component: () => import('@/components/resellers/BillingDate.vue'),
                    props: {
                        type: 'ended'
                    }
                },
            ],
            loading: false,
            noGroups: false,
        };
    },
    computed: {
        ...mapState({
            selectedGroups: (state) => state.agency.selectedGroups,
            groupsLoading: (state) => state.agency.groupsLoading,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        selectedGroupsIds() {
            const ids = this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
            if (ids.length) {
                return ids.join();
            } else {
                return '';
            }
        },
        activeMonth() {
            return moment(this.currentDateRange.start).format('MM-YYYY');
        }
    },
    watch: {
        selectedGroups() {
            this.checkGroupsAndLoad();
        },
        currentDateRange() {
            if (!this.selectedGroups.includes(null)) { this.getProducts() }
        }
    },
    mounted() {
        this.checkGroupsAndLoad();
    },
    methods: {
        checkGroupsAndLoad() {
            if (this.selectedGroups.length && !this.selectedGroups.includes(null)) {
                this.noGroups = false;
            } else {
                this.noGroups = true;
            }
            this.getProducts();
        },
        getDomain(url) {
            try {
                const urlObj = new URL(url);
                return urlObj.hostname;
            } catch {
                return '';
            }
        },
        async getProducts() {
            this.loading = true;
            this.emptyProducts();

            const data = {
                groupIds: this.selectedGroupsIds,
                month: this.activeMonth,
                agency_id: this.currentAgency.id
            };

            try {
                const response = await (new HTTP).post('/reports/billing', data);
                this.setProductsWithData(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        setProductsWithData(data) {

            const products = [];
            const dealerProducts = [];

            // Process the data which will include an array of products
            // and dealers associated with that product
            data.forEach(productGroup => {

                // Drop the dealers key from the product group to conserve memory
                // and clean up data formatting downstream
                const product = _.omit(productGroup, 'dealers');

                // Track the product
                products.push(product);

                // Guard to ensure dealers are set
                if (!productGroup.dealers) {
                    return;
                }

                // Loop through underlying dealers to build up the dealers state
                productGroup.dealers.forEach(dealer => {
                    // Combine the product group and the dealer so the table
                    // can include all relevant data for the row
                    dealerProducts.push({
                        ...dealer,
                        ...product
                    });
                });
            });

            // Update state with the resulting arrays
            this.products = products;
            this.dealerProducts = dealerProducts;
            this.dealerProductsDisplayed = dealerProducts;
        },

        filterByProduct(productId) {

            // If null was passed reset the displayed products
            if (!productId) {
                this.activeFilter = null;
                this.resetDisplay();
                return;
            }

            this.activeFilter = productId;

            // Filter the displayed products down to those that match the filtered product ID
            this.dealerProductsDisplayed = this.dealerProducts.filter(dealerProduct => {
                return (productId === dealerProduct.product_id);
            });
        },

        emptyProducts() {
            this.products = [];
            this.dealerProducts = [];
            this.dealerProductsDisplayed = [];
        },

        resetDisplay() {
            this.dealerProductsDisplayed = this.dealerProducts;
        },

        onCopy(e) {
            this.$flash(`${e.text} copied to clipboard!`, 'green');
        },

        exportCSV() {

            const productExport = this.dealerProducts.map(dealerProduct => {

                const startedAt = (dealerProduct.product_started) ?
                    this.$moment
                        .utc(dealerProduct.product_started.date)
                        .local()
                        .format('YYYY-MM-DD hh:mm:ss')
                    : '';

                const endedAt = (dealerProduct.product_ended) ?
                    this.$moment
                        .utc(dealerProduct.product_ended.date)
                        .local()
                        .format('YYYY-MM-DD hh:mm:ss')
                    : '';

                return {
                    name: dealerProduct.name,
                    id: dealerProduct.id,
                    website: dealerProduct.site_base_url,
                    external_id: dealerProduct.external_id,
                    product: dealerProduct.product,
                    product_id: dealerProduct.product_id,
                    product_started: startedAt,
                    product_ended: endedAt
                };
            });

            const fileName = `ResellerBilling_${this.currentAgency.name.replace(' ','')}_${this.activeMonth}`;

            exportToCsv(fileName, productExport);
        }
    }
};
</script>

<style lang="scss" scoped>
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 40px;
    &__holder {
        max-width: 255px;
    }
}
.ellipsis {
    position: relative;
    &:before {
        content: '&nbsp;';
        visibility: hidden;
    }
    span {
        position: absolute;
        left: 25px;
        right: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.stats-element {
    text-align: center;
    padding: 25px;
    &.active {
        background-color: $bg-light-container;
    }
    span {
        color: $light-grey-title;
    }

    h4 {
        font-size: 36px;
        line-height: 34px;

        @media (min-width: 960px) and (max-width: 1200px){
            font-size: 28px;
        }
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    h5 {
        font-size: 28px;
        line-height: 30px;
    }

    .text--green {
        color: $green;
    }
}
</style>